html {
	font-family: "Poppins", sans-serif;
	min-height: 100vh;
}

body {
	font-family: "Poppins", sans-serif;
	min-height: 100vh;
}

main {
	min-height: 100vh;
}

.bg-landing {
	background-color: #FFFFFF;
	/* background-image: url(../img/static/signup_background.svg); */
	min-height: 100vh;
}
.font-size-16 {
	font-size: 16px;
}

.font-size-14 {
	font-size: 14px;
}

.pointer {
	cursor: pointer;
}

.default {
	cursor: default;
}
